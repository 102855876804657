// extracted by mini-css-extract-plugin
export var breakdown = "style-module--breakdown--UZh8C";
export var breakdownBlue = "style-module--breakdownBlue--VaBpt";
export var breakdownRed = "style-module--breakdownRed--r8ile";
export var chart = "style-module--chart--13rMW";
export var controls = "style-module--controls--+XaX4";
export var item = "style-module--item--uB3sj";
export var itemOuter = "style-module--itemOuter--Ktf7Q";
export var menu = "style-module--menu--1V6fB";
export var menuActive = "style-module--menuActive--O8gi-";
export var point = "style-module--point--vTqq4";
export var qr = "style-module--qr--U6YIj";
export var supply = "style-module--supply--dnHgP";
export var supplyLarge = "style-module--supplyLarge--N1fu2";